

































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import DstvPackage, { DstvPackageItem } from "@/components/DstvPackage.vue";

@Component({
    components: { DstvPackage, BackButton },
})
export default class ChannelsIndex extends Vue {
    dstvPackageList: Array<DstvPackageItem> = [
        {
            name: "Comfam",
            channels: "120+",
            price: 9500,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_CONFAM,
            },
        },
        {
            name: "Compact",
            channels: "135+",
            price: 13500,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_COMPACT,
            },
        },
        {
            name: "Compact Plus",
            channels: "155+",
            price: 19000,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_COMPACT_PLUS,
            },
        },
        {
            name: "Premium",
            channels: "175+",
            price: 26500,
            route: {
                name: this.$constants.ROUTE_NAMES.DSTV_CHANNELS_PREMIUM,
            },
        },
    ];
}
